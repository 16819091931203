(function () {
  'use strict';

  /* @ngdoc object
   * @name settings.events
   * @description
   *
   */
  angular
    .module('settings.events', [
      'ui.router',
      'events.edit',
      'events.list'
    ]);
}());
